<template>
  <ConfirmDialog />
  <Dialog
    ref="dialog"
    v-model:visible="mostrarModal"
    :style="{ width: '1200px' }"
    header="Preregistro de clientes"
    :modal="true"
    :maximizable="true"
    class="p-fluid"
    @hide="ocultarDialog"
    @show="maximizarDialog"
  >
    <div class="p-fluid formgrid grid">
      <div class="grid field col-12 md:col-4">
        <div class="field col-12 md:col-4">
          <span>1MER NOMBRE:</span>
          <InputText v-model="data_preregistro.nombre1" readonly />
        </div>
        <div class="field col-12 md:col-4">
          <span>2DO NOMBRE:</span>
          <InputText v-model="data_preregistro.nombre2" readonly />
        </div>
        <div class="field col-12 md:col-4">
          <span>A. PATERNO:</span>
          <InputText v-model="data_preregistro.apellido_paterno" readonly />
        </div>
        <div class="field col-12 md:col-4">
          <span>A. MATERNO:</span>
          <InputText v-model="data_preregistro.apellido_materno" readonly />
        </div>
        <div class="field col-12 md:col-3">
          <span>CI/NIT:</span>
          <InputText v-model="data_preregistro.ci_nit" readonly />
        </div>
        <div class="field col-12 md:col-2">
          <span>EXP.:</span>
          <InputText v-model="data_preregistro.ci_expedido" readonly />
        </div>
        <div class="field col-12 md:col-3">
          <span>SEXO:</span>
          <InputText v-model="data_preregistro.sexo" readonly />
        </div>
        <div class="field col-12 md:col-4">
          <span>FECHA NAC.:</span>
          <InputText v-model="data_preregistro.fecha_nacimiento" readonly />
        </div>
        <div class="field col-12 md:col-4">
          <span>TELEFONO:</span>
          <div class="p-inputgroup">
            <InputText v-model="data_preregistro.telefono" readonly />
            <a
              class="estilo-whatsapp"
              :href="`https://wa.me/591${data_preregistro.telefono}`"
              target="_blank"
              v-tooltip.top="
                'Enviar mensaje de WhatsApp a: ' + data_preregistro.telefono
              "
            >
              <i class="pi pi-whatsapp"></i>
            </a>
          </div>
        </div>
        <div class="field col-12 md:col-4" v-if="data_preregistro.correo">
          <span>CORREO:</span>
          <Textarea
            v-model="data_preregistro.correo"
            readonly
            rows="1"
            autoResize
          />
        </div>
        <div class="field col-12 md:col-4">
          <span>CONVENIO:</span>
          <Textarea
            v-model="data_preregistro.convenio"
            readonly
            rows="1"
            autoResize
          />
        </div>
        <div class="field col-12 md:col-4">
          <span>FECHA CITA:</span>
          <InputText v-model="data_preregistro.fecha_cita" readonly />
        </div>
        <div class="field col-12 md:col-4">
          <span>HORA CITA:</span>
          <InputText v-model="data_preregistro.hora_cita" readonly />
        </div>
        <div class="field col-12 md:col-6">
          <span>DIRECCION:</span>
          <Textarea
            v-model="data_preregistro.direccion"
            readonly
            rows="1"
            autoResize
          />
        </div>
        <div class="field col-12 md:col-6">
          <span>OBSERVACIONES:</span>
          <Textarea
            v-model="data_preregistro.observaciones"
            readonly
            rows="1"
            autoResize
          />
        </div>
        <div class="field col-12 md:col-12">
          <span>DOCUMENTOS/IMAGENES:</span>
          <div v-if="documentos.length">
            <div class="grid mt-4">
              <div
                v-for="(documento, index) in parseDocumentos(
                  data_preregistro.documentos || '[]'
                )"
                :key="index"
                class="field col-12 md:col-3"
              >
                <div class="documento-card">
                  <template v-if="documento.type.startsWith('image/')">
                    <Image
                      :src="getImageUrl(documento.path)"
                      width="120"
                      height="110"
                      alt="imagen"
                      preview
                    />
                  </template>
                  <template v-else-if="documento.type === 'application/pdf'">
                    <a
                      :href="getImageUrl(documento.path)"
                      v-tooltip.top="'Ver PDF: ' + documento.name"
                      target="_blank"
                      class="pdf-link"
                    >
                      <i class="pi pi-file-pdf"></i> Ver PDF
                    </a>
                  </template>
                  <div class="documento-title">{{ documento.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <p>No hay documentos disponibles.</p>
          </div>
        </div>
      </div>
      <div class="field col-12 md:col-8">
        <AgendaList
          :mostrar_agenda="mostrar_agenda"
          :pre_registro_id="data_preregistro.id"
          @cerrarModalPreregistro="ocultarDialog"
          @actualizarListadoPreregistro="actualizarListadoPreregistro"
        />
      </div>
    </div>
    <template #footer>
      <div class="field">
        <Button
          label="CERRAR"
          icon="pi pi-times"
          class="p-button-secondary p-button-lg"
          @click="ocultarDialog"
        />
        <Button
          v-if="data_preregistro.estado === 0"
          label="RECHAZAR"
          icon="pi pi-times"
          class="p-button-danger p-button-lg"
          v-tooltip.top="'Rechazar preregistro'"
          @click="cambiarEstado()"
          :loading="loading"
        />
        <Button
          v-show="false"
          v-if="data_preregistro.estado === 0"
          label="PACIENTE AGENDADO"
          icon="pi pi-calendar"
          class="p-button-info p-button-lg"
          v-tooltip.top="'Marcar como paciente agendado'"
          @click="cambiarEstado()"
          :loading="loading"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import ClienteService from "@/service/ClienteService";
import AgendaList from "@/module/agenda/AgendaList.vue";

export default {
  components: {
    AgendaList,
  },
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    preregistro: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      mostrarModal: this.show,
      data_preregistro: this.preregistro,
      documentos: this.parseDocumentos(this.preregistro.documentos || "[]"),
      loading: false,
      mostrar_agenda: false,
    };
  },
  created() {
    this.clienteService = new ClienteService();
  },
  methods: {
    actualizarListadoPreregistro() {
      this.$emit("actualizarListado");
    },
    maximizarDialog() {
      // Llamamos al método maximize del modal
      this.$refs.dialog.maximize();
    },
    cambiarEstado() {
      this.loading = true;
      let datos = {
        id: this.data_preregistro.id,
      };
      this.clienteService
        .cambiarEstadoPreregistro(datos)
        .then((data) => {
          if (data.success === false) {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: data.message,
              life: 5000,
            });
            this.loading = false;
            return;
          }
          this.$toast.add({
            severity: "success",
            summary: "Exito",
            detail: data.message,
            life: 5000,
          });
          this.loading = false;
          this.$emit("actualizarListado");
          this.ocultarDialog();
        })
        .catch((error) => {
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Error al cambiar estado de preregistro" + error,
            life: 5000,
          });
        });
    },
    ocultarDialog() {
      this.$emit("closeModal");
    },
    parseDocumentos(documentos) {
      try {
        return JSON.parse(documentos) || [];
      } catch (e) {
        console.error("Error al analizar documentos JSON:", e);
        return [];
      }
    },
    getImageUrl(path) {
      // Devuelve la URL completa para la imagen en el servidor
      return `https://www.future.com.bo/${path}`; // Reemplaza con tu dominio y ruta de imagen
    },
  },
  watch: {
    show(value) {
      this.mostrarModal = value;
    },
    preregistro(value) {
      this.data_preregistro = value;
      this.documentos = this.parseDocumentos(value.documentos || "[]");
      this.data_preregistro.sexo =
        value.sexo === "53" ? "MASCULINO" : "FEMENINO";
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.documento-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 0.5rem;
  border: 1px solid #ced4da;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  text-align: center;
  height: 100%;
  overflow: hidden;
}

.documento-card:hover {
  transform: scale(1.05);
}

.pdf-link {
  color: red;
  font-size: 1.5rem;
  text-decoration: none;
  margin-top: 1rem;
  display: inline-flex;
  align-items: center;
}

.pdf-link i {
  font-size: 2rem;
  margin-right: 0.5rem;
}

.documento-title {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 0.5rem;
  word-wrap: break-word;
}
// estilo a los input
.p-inputtext {
  width: 100%;
  font-size: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-weight: bold;
}
//ahora al span
.span {
  font-style: italic;
  color: #6c757d;
  font-weight: 600;
}
.estilo-whatsapp {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #25d366;
  color: #fff;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem;
  transition: background-color 0.3s ease-in-out;
}
</style>
