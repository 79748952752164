<template>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Agenda</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">Pre-registros</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <Toast />
    <div
      class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
    >
      <div>
        <div class="font-medium text-3xl text-900">
          Administrar Pre-registros
          <Badge size="xlarge" severity="success"> {{ totalRecords }}</Badge>
        </div>
      </div>
    </div>
    <div class="col-12 estilos-form mt-2">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-2">
          <span><strong>F. REG. DESDE:</strong></span>
          <Calendar v-model="fecha_inicio" dateFormat="dd/mm/yy" showIcon />
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>F. REG. HASTA:</strong></span>
          <Calendar v-model="fecha_fin" dateFormat="dd/mm/yy" showIcon />
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>CI/NIT:</strong></span>
          <InputNumber
            :class="'text-right'"
            mode="decimal"
            v-model="ci_nit"
            :useGrouping="false"
            @keyup.enter="obtenerListadoPreregistros()"
          />
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>CONVENIO:</strong></span>
          <Dropdown
            :filter="true"
            v-model="convenio"
            :options="convenios"
            optionLabel="nombre"
            optionValue="id"
            @change="obtenerListadoPreregistros()"
          />
        </div>
        <div class="field col-12 md:col-1">
          <span><strong>REGISTROS:</strong></span>
          <Dropdown
            v-model="cantidadSeleccionada"
            :options="cantidades"
            optionLabel="label"
            optionValue="value"
            @change="mostrarMasRegistros()"
          />
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>ESTADO:</strong></span>
          <Dropdown
            v-model="estadoSeleccionado"
            :options="estados"
            optionLabel="nombre"
            optionValue="id"
            @change="obtenerListadoPreregistros()"
          />
        </div>
        <div class="field col-12 md:col-1">
          <span>&nbsp;</span>
          <Button
            label="Bus..."
            v-tooltip.top="'Buscar Pre-registros'"
            icon="pi pi-search"
            class="p-button-info"
            @click="obtenerListadoPreregistros()"
            :loading="cargando"
          />
        </div>
      </div>
    </div>
    <div class="mt-2">
      <DataTable
        ref="dtpreregistros"
        :value="preregistros"
        :rowClass="rowClass"
        :loading="cargando"
        dataKey="id"
        :paginator="true"
        :rows="20"
        :rowHover="true"
        class="p-datatable-sm"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Preregistros"
        responsiveLayout="scroll"
        stripedRows
        showGridlines
        selectionMode="single click"
        @row-dblclick="editarPreregistro($event.data)"
      >
        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span class="flex align-items-center justify-content-center p-invalid"
            >No existen Registros!</span
          ></template
        >
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>
        <Column field="nombre1" header="NOMBRES">
          <template #body="{ data }">
            <span>{{ data.nombre1 }} {{ data.nombre2 }}</span>
          </template>
        </Column>
        <Column field="apellido_paterno" header="APELLIDOS">
          <template #body="{ data }">
            <span>{{ data.apellido_paterno }} {{ data.apellido_materno }}</span>
          </template>
        </Column>
        <Column field="ci_nit" header="CI/NIT"> </Column>
        <Column field="convenio" header="CONVENIO"> </Column>
        <Column field="telefono" header="TELEFONO" style="width: 95px">
          <template #body="{ data }">
            <a
              :href="`https://wa.me/591${data.telefono}`"
              target="_blank"
              v-tooltip.top="'Enviar mensaje de WhatsApp'"
              class="p-link p-0 mr-1"
              >{{ data.telefono }} <i class="pi pi-whatsapp"></i
            ></a>
          </template>
        </Column>
        <Column field="convenio" header="CONVENIO"> </Column>
        <Column field="fecha_registro" header="F.REGISTRO" style="width: 110px">
          <template #body="{ data }">
            <span>{{ formatDate(data.fecha_registro) }}</span>
          </template>
        </Column>
        <Column
          field="documentos"
          header="DOCUMENTOS"
          style="width: 120px"
          v-if="false"
        >
          <template #body="{ data }">
            <div v-if="data.documentos && data.documentos.length">
              <div class="documentos-container">
                <div
                  v-for="(documento, index) in parseDocumentos(data.documentos)"
                  :key="index"
                  class="documento-item"
                >
                  <template v-if="documento.type.startsWith('image/')">
                    <!-- Mostrar imágenes -->
                    <Image
                      :src="getImageUrl(documento.path)"
                      alt="Imagen"
                      width="20"
                      height="15"
                      class="documento-image"
                      preview
                    />
                  </template>
                  <template v-else-if="documento.type === 'application/pdf'">
                    <!-- Mostrar enlace para archivos PDF -->
                    <a
                      :href="getImageUrl(documento.path)"
                      v-tooltip.top="'Ver PDF: ' + documento.name"
                      target="_blank"
                      class="pdf-link"
                    >
                      <i class="pi pi-file-pdf"></i> Ver PDF
                    </a>
                  </template>
                  <template v-else>
                    <!-- Mostrar texto para otros tipos de documentos -->
                    <span>Archivo no soportado</span>
                  </template>
                </div>
              </div>
            </div>
            <div v-else>-</div>
          </template>
        </Column>
        <Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <Badge
              :severity="
                data.estado === 0
                  ? 'danger'
                  : data.estado === 1
                  ? 'warning'
                  : data.estado === 2
                  ? 'info'
                  : 'info'
              "
            >
              {{ textoEstado(data.estado) }}
            </Badge>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
  <PreregistroModal
    :show="modalPreregistro"
    :preregistro="preregistro"
    @closeModal="modalPreregistro = false"
    @actualizarListado="obtenerListadoPreregistros"
  />
</template>

<script>
import ClienteService from "@/service/ClienteService";
import { useAuth } from "@/stores";
import PreregistroModal from "@/module/clientes/PreregistroModal.vue";

export default {
  components: {
    PreregistroModal,
  },
  data() {
    return {
      totalRecords: 0,
      preregistros: [],
      cargando: false,
      enviado: false,
      errors: {},
      fecha_inicio: null,
      fecha_fin: null,
      ci_nit: null,
      convenio: 0,
      cantidadSeleccionada: 20,
      estadoSeleccionado: 0,
      estados: [
        { id: 4, nombre: "TODOS" },
        { id: 0, nombre: "PENDIENTE" },
        { id: 1, nombre: "CONFIRMADO" },
        { id: 2, nombre: "RECHAZADO" },
      ],
      convenios: [],
      cantidades: [
        { label: "20", value: 20 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
        { label: "200", value: 200 },
        { label: "500", value: 500 },
      ],
      modalPreregistro: false,
      preregistro: {},
    };
  },
  clienteService: null,
  auth: null,
  created() {
    this.auth = useAuth();
    this.clienteService = new ClienteService();
  },
  mounted() {
    this.obtenerListadoPreregistros();
  },
  methods: {
    rowClass(data) {
      return {
        "row-verificado": data.estado === 1,
      };
    },
    editarPreregistro(preregistro) {
      this.modalPreregistro = true;
      this.preregistro = preregistro;
    },
    mostrarMasRegistros() {
      this.obtenerListadoPreregistros();
    },

    parseDocumentos(documentos) {
      try {
        return JSON.parse(documentos) || [];
      } catch (e) {
        console.error("Error al analizar documentos JSON:", e);
        return [];
      }
    },
    getImageUrl(path) {
      // Devuelve la URL completa para la imagen en el servidor
      return `https://www.future.com.bo/${path}`; // Reemplaza con tu dominio y ruta de imagen
    },
    obtenerListadoPreregistros() {
      this.cargando = true;
      let datos = {
        fecha_inicio: this.fecha_inicio,
        fecha_fin: this.fecha_fin,
        ci_nit: this.ci_nit,
        convenio: this.convenio,
        cantidad: this.cantidadSeleccionada,
        estado: this.estadoSeleccionado,
      };
      this.clienteService.getPreregistros(datos).then((data) => {
        this.totalRecords = data.preregistros.length || 0;

        this.preregistros = data.preregistros || [];
        this.convenios = data.convenios || [];

        //seleccion en el select todos
        this.convenios.unshift({ id: 0, nombre: "TODOS" });

        this.cargando = false;
      });
    },
    textoEstado(estado) {
      switch (estado) {
        case 0:
          return "PENDIENTE";
        case 1:
          return "AGENDADO";
        case 2:
          return "RECHAZADO";
        default:
          return "SIN ESTADO";
      }
    },
    formatDate(fecha) {
      if (fecha) {
        const dateObj = new Date(fecha);
        const day = String(dateObj.getDate()).padStart(2, "0");
        const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Los meses comienzan desde 0
        const year = dateObj.getFullYear();

        const hours = String(dateObj.getHours()).padStart(2, "0");
        const minutes = String(dateObj.getMinutes()).padStart(2, "0");

        // Formato: dd/mm/yyyy hh:mm
        return `${day}/${month}/${year} ${hours}:${minutes}`;
      }
      return "";
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}
.documentos-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px; /* Espacio entre los elementos */
}

.documento-item {
  display: flex;
  align-items: center;
  gap: 8px; /* Espacio entre el ícono y el texto del enlace PDF */
}

.documento-image {
  border: 1px solid #ccc; /* Opcional: Añadir borde a las imágenes */
  border-radius: 4px; /* Opcional: Añadir bordes redondeados */
}

.pdf-link {
  color: red;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.pdf-link i {
  margin-right: 5px; /* Espacio entre el ícono y el texto */
}
.estilos-form {
  background-color: #ececec;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
}
.p-link {
  color: #000000;
  text-decoration: none;
  font-weight: bold;
}
.pi-whatsapp:before {
  background-color: #25d366;
  color: #fff;
  border-radius: 50%;
}
:deep(.row-verificado) {
  font-weight: bold;
  background-color: #ffd700 !important;
}
//entro al badge y cambio el warning por el color que quiero
:deep(.p-badge-warning) {
  inline-size: 100%;
  background-color: #ffd700 !important;
  color: #000000 !important;
  border: 1px solid #000000 !important;
}
:deep(.p-badge-danger) {
  inline-size: 100%;
  background-color: #ff0000 !important;
  color: #ffffff !important;
  border: 1px solid #000000 !important;
}
:deep(.p-badge-info) {
  inline-size: 100%;
  background-color: #0000ff !important;
  color: #ffffff !important;
  border: 1px solid #000000 !important;
}
</style>
